import React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { cartContext } from '../Context/CartContext';
import { Badge } from 'react-bootstrap';
import { BsCart4 } from "react-icons/bs";

const CartWidget = () => {
  const { cartLocal } = useContext(cartContext);
  const totalUnidades = !cartLocal ? 0 : cartLocal.reduce((total, producto) => total + producto.contador, 0);

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px', margin: '0 15px' }}>
      {totalUnidades > 0 && (
        <Link to='/cart' className='d-flex align-items-center'>
          <BsCart4 size={24} style={{ color: 'white', marginRight: '10px' }} />
          <Badge pill bg="danger" style={{ color: 'white', fontSize: '12px', lineHeight: '1', transform: 'translateY(-10px) translateX(-8px)', }}>
            {totalUnidades}
          </Badge>
        </Link>
      )}
    </div>
  );
};

export default CartWidget;
