import React from 'react';
import "./CarruselMarcasStyle.css";
import { marcas } from '../../config/config';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const CarruselMarcas = () => {
  const country = process.env.REACT_APP_COUNTRY;
  const marcasPais = marcas[country];

  const Title = styled.h2`
    text-align: center; /* Centrar el texto */
    text-transform: uppercase; /* Convertir a mayúsculas */
    font-family: __Inter_723b8c, __Inter_Fallback_723b8c, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -1.2px;
    color: #000000;
    margin-top: 2em;
    margin-bottom: 1.5em;
    font-weight: bold;

    @media (max-width: 768px) {
        margin-top: 10px;
    }

    span {
        color: red; /* Color diferente para la parte "redes sociales" */
    }
`;

  return (

    <div>
        <Title>Marcas líderes del mercado disponibles en <span>Solution Box</span></Title>
      <div className='slider mt-5'>
      
        <div className='slide-track'>
          {marcasPais.map((marca, index) => (
            <Link key={index} to={`/marcas/${marca.id}`}>
              <div className="slide">
                <img
                  src={`${process.env.PUBLIC_URL}/${marca.src}`}
                  alt={marca.alt}
                  width="55%"
                  height="auto"
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};