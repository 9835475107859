import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import CartWidget from "../Carrito/CartWidget";
import BtnLogout from "./BtnLogout";
import "./Navbar.css";
import Swal from "sweetalert2";
import Dropdown from 'react-bootstrap/Dropdown';
import { authContext } from "../Context/AuthContext";
import { useTranslation } from "react-i18next";

export const BtnLoggin = () => {
  const { token, user, desloguearse2 } = useContext(authContext);
  const navigate = useNavigate();
  const country = process.env.REACT_APP_COUNTRY;
  const [tasaDeCambio, setTasaDeCambio] = useState(null);

  const [t] = useTranslation("global")

  // Cotizacion de Costa Rica con api externa hasta que traiga Cotizacion
  useEffect(() => {
    const obtenerTipoDeCambio = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        const nuevaTasaDeCambio = data.rates.CRC;
        setTasaDeCambio(nuevaTasaDeCambio);
      } catch (error) {
        console.error('Error al obtener el tipo de cambio:', error);
      }
    };

    obtenerTipoDeCambio();
  }, []);


  const eleccionRegistro = () => {
    Swal.fire({
      html: `<h3><b>${t("login.exclusivo-h3")}</b></h3> <br> <h5>${t("login.exclusivo-h5")}</h5>`,
      showCancelButton: false,
      confirmButtonText: `${t("login.alta-web")}`,
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/registroUsuario`);
      } else {
        navigate(`/contacto`);
      }
    });
  };

  if (!token) {
    return (
      <div className="btnLogueado2">
        <Dropdown align={{ lg: 'end' }} id="dropdown-menu-align-responsive-1">
          <Dropdown.Toggle style={{ backgroundColor: '#212529', right: 0, borderColor: '#212529' }} id="dropdown-basic">
            <i className="fa-solid fa-user"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ backgroundColor: 'black', right: 0 }}>
            <Dropdown.Item onClick={() => navigate("/login")}><i className="fa-solid fa-right-from-bracket"></i> {t("login.iniciar-sesion")}</Dropdown.Item>
            <Dropdown.Item onClick={eleccionRegistro}><i className="fa-solid fa-address-card"></i> {t("login.registrarse")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  if (!user) {
    return (
      <>
        <div className="btnLogueado">
          <div className="btnLogueado2">
            <span>{t("login.bienvenido")}</span>
            <CartWidget />
            <Button variant="success" onClick={() => navigate("/profile")}>
              {t("login.perfil")}
            </Button>
            <BtnLogout />
          </div>
        </div>
      </>
    );
  }

  let rucPais = ""

  if (country === "PY") {
    rucPais =
      <span>{`${user.Nombre} ${user.Apellido} (RUC: ${user.Cuit})`}</span>
  } else {
    rucPais =
      <span>{`${user.Nombre} ${user.Apellido} (CUIT: ${user.Cuit})`}</span>
  }

  return (
    <>
      {country !== "USA" ? (
        <div className="btnLogueado">
          {/* <span>{t("login.bienvenido")} {user.Nombre} {user.Apellido}</span> */}
          {/* <span> {user.Cliente ? "Numero de cliente: " + user.Cliente : ""}</span> */}
          <span>{rucPais}
          </span>
          <span> {user.Cliente ? "Numero de cliente: " + user.Cliente : ""}</span>
          {user.Cotizacion ? (
            <span>{`1 USD = ${user.Cotizacion}`}</span>
          ) : (
            country === 'CR' && tasaDeCambio && (
              <span>{`1 USD = ₡ ${tasaDeCambio}`}</span>
            )
          )}
        </div>
      ) : (
        <div className="btnLogueado">
          <span>Welcome {user.Nombre} {user.Apellido}</span>
        </div>
      )}

      <div className="cart-widg-cont">
        <CartWidget />
      </div>

      <div className="btnLogueado2">
        <Dropdown align={{ lg: 'end' }} id="dropdown-menu-align-responsive-1">
          <Dropdown.Toggle style={{ backgroundColor: '#38b000', right: 0, borderColor: '#38b000' }} id="dropdown-basic">
            <i className="fa-solid fa-user" style={{
              color: '#ffffff'
            }}></i>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ backgroundColor: 'black', right: 0 }}>
            <Dropdown.Item onClick={() => navigate('/profile')}><i className="fa-solid fa-user"></i> Ver mi perfil</Dropdown.Item>
            <Dropdown.Item onClick={() => desloguearse2('CS')}><i className="fa-solid fa-right-from-bracket"></i> Cerrar sesión</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};
